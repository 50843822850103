import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ContactLauri from "../components/ContactLauri";

import Layout from "../components/Layout"
import Header from '../components/Header'
import Seo from "../components/Seo"

const Portfolio = () => {
  return (
    <Layout>
      <Seo title="Portfolio" />
      <Header />

      <div className="container-base">
        <div className="section-1-2-center">
          <h1 className="title-xl wel-mb-s">Portfolio</h1>
          <p className="body-l">The construction industry is changing and factory construction is underway one of the main directions of the wave of innovation. See how we've helped our partners get better results.</p></div>
      </div>

      <div className="bg-welement-pink">
        <div className="container-base">
          <div className="section-1-2-center">
            <h2 className="title wel-mb-l">Facade elements</h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">


            <div className="text-center">
              <StaticImage
                src="../images/portfolio/paju.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="16-storey office building in the heart of Tartu"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Paju 2 office building</p>
              <p className="body wel-mb-xs">16-storey office building in the heart of Tartu</p>
              <p className="body-s">Tartu, Estonia</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/hugo.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="6-storey hostel in the center of Tartu"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Hugo.Stay hostel</p>
              <p className="body wel-mb-xs">6-storey hostel in the center of Tartu</p>
              <p className="body-s">Tartu, Estonia</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/knivsta.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Facade elements of an apartment building"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Knivsta apartment building</p>
              <p className="body wel-mb-xs">Facade elements of an apartment building</p>
              <p className="body-s">Knivsta, Sweden</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/siili.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Facade elements of an apartment building"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Siili apartment building</p>
              <p className="body wel-mb-xs">Facade elements of an apartment building</p>
              <p className="body-s">Tartu, Estonia</p>
            </div>


          </div>
        </div>

      </div>

      <div className="bg-welement-white">
        <div className="container-base">
          <div className="section-1-2-center">
            <h2 className="title wel-mb-l">Private houses</h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/drobak.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Four unique private houses near Oslo, in the small town of Drobak"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Drobak houses</p>
              <p className="body wel-mb-xs">Four unique private houses near Oslo, in the small town of Drobak</p>
              <p className="body-s">Drobak, Norra</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/hiiekula.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Six private houses near Tartu. Welement designed, engineered and manufactured the houses."
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Hiieküla house</p>
              <p className="body wel-mb-xs">Six private houses near Tartu. Welement designed, engineered and manufactured the houses.</p>
              <p className="body-s">Hiieküla, Tartu</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/tartu1.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Design, engineering, production, installation"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">House in Tartu</p>
              <p className="body wel-mb-xs">Design, engineering, production, installation</p>
              <p className="body-s">Tartu, Estonia</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/kloogaranna.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Design, engineering, production, installation"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Kloogaranna house</p>
              <p className="body wel-mb-xs">Design, engineering, production, installation</p>
              <p className="body-s">Kloogaranna, Estonia</p>
            </div>


          </div>
        </div>

      </div>

      <div className="bg-welement-pink">
        <div className="container-base">
          <div className="section-1-2-center">
            <h2 className="title wel-mb-l">Townhouses and apartment buildings</h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/oa.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Two terraced houses and one two-storey apartment building. Welementi The team designed, manufactured and installed modular terraced houses and as an elementary solution for an apartment building."
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Development of Oa Street</p>
              <p className="body wel-mb-xs">Two terraced houses and one two-storey apartment building. Welementi The team designed, manufactured and installed modular terraced houses and as an elementary solution for an apartment building.</p>
              <p className="body-s">Tartu, Estonia</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/island.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Single-storey townhouses are located on the outskirts of Reikyavik. Welement's team designed and manufactured factory-made elements."
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Row houses in Iceland</p>
              <p className="body wel-mb-xs">Single-storey townhouses are located on the outskirts of Reikyavik. Welement's team designed and manufactured factory-made elements.</p>
              <p className="body-s">Reikyavik, Island</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/helsinki.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Design, production, installation"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Row houses in Helsinki</p>
              <p className="body wel-mb-xs">Design, production, installation</p>
              <p className="body-s">Helsinki, Finland</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/holmenkollen.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Four semi-detached houses in Oslo's most prestigious Holmenkollen area"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Holmenkollen semi-detached houses</p>
              <p className="body wel-mb-xs">Four semi-detached houses in Oslo's most prestigious Holmenkollen area</p>
              <p className="body-s">Oslo, Norway</p>
            </div>


          </div>
        </div>

      </div>

      <div className="bg-welement-white">
        <div className="container-base">
          <div className="section-1-2-center">
            <h2 className="title wel-mb-l">Community buildings</h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-8 flex md:w-3/4 mx-auto">

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/lillkyrka.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Two-storey care homes in Sweden"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Lillkyrka LSS Care Home</p>
              <p className="body wel-mb-xs">Two-storey care homes in Sweden</p>
              <p className="body-s">Lillkyrka, Sweden</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/nupsik.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="One-storey kindergarten in the suburbs of Tartu"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Nupsiku Kindergarten</p>
              <p className="body wel-mb-xs">One-storey kindergarten in the suburbs of Tartu</p>
              <p className="body-s">Tartu, Estonia</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/aland.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Welement's team designed, manufactured and installed factory-made elements"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Hotel in Åland</p>
              <p className="body wel-mb-xs">Welement's team designed, manufactured and installed factory-made elements</p>
              <p className="body-s">Ahvenamaa</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/nyby.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Two-storey care homes Swedens"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Nyby LSS Care Home</p>
              <p className="body wel-mb-xs">Two-storey care homes Swedens</p>
              <p className="body-s">Nyby, Sweden</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/orebro.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Two-storey care homes in Sweden"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Örebro LSS Care Home</p>
              <p className="body wel-mb-xs">Two-storey care homes in Sweden</p>
              <p className="body-s">Örebro, Sweden</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/almunge.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Two-storey care homes in Sweden"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Almunge LSS Care Home</p>
              <p className="body wel-mb-xs">Two-storey care homes in Sweden</p>
              <p className="body-s">Almunge, Sweden</p>
            </div>


          </div>
        </div>

      </div>

      <div className="bg-welement-pink">
        <div className="container-base">
          <div className="section-1-2-center">
            <h2 className="title wel-mb-l">Product development</h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-8 md:w-3/4 mx-auto">

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/boklok.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Auxiliary buildings at various sites in Sweden"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Bo Klok Auxiliary building</p>
              <p className="body wel-mb-xs">Auxiliary buildings at various sites in Sweden</p>
              <p className="body-s">Various objects in Sweden</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/taju.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="For various customers in Scandinavia and the Baltics"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">taju.space</p>
              <p className="body wel-mb-xs">Personal multimedia space</p>
              <p className="body-s">For various customers in Scandinavia and the Baltics</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/3by3.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="For various customers in Scandinavia and the Baltics"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">3by3 House</p>
              <p className="body wel-mb-xs">Modular single-family experience</p>
              <p className="body-s">For various customers in Scandinavia and the Baltics</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/riffen.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Welement designed, designed and manufactured a restaurant in the heart of Tartu"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Restaurant RIFFEN</p>
              <p className="body wel-mb-xs">Welement designed, designed and manufactured a restaurant in the heart of Tartu</p>
              <p className="body-s">Tartu, Estonia</p>
            </div>

            <div className="text-center">
              <StaticImage
                src="../images/portfolio/valgussaun.png"
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Lighting installation in collaboration with artist Timo Toots"
                loading="eager"
                placeholder="blurred"
                className="w-full rounded wel-mb-s" />
              <p className="title-s">Valgussaun</p>
              <p className="body wel-mb-xs">Lighting installation in collaboration with artist Timo Toots</p>
              <p className="body-s">Maajaam, Estonia</p>
            </div>


          </div>
        </div>

      </div>

      <ContactLauri />

    </Layout>
  )

};

export default Portfolio;

